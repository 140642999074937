import React from "react";
import UsersLogic from "./UsersLogic";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import Paper from "@mui/material/Paper";
import { Snackbar } from "@mui/material";
import { RoleModal } from "./Role";
import AdminRightsModal from './AdminRightsModal';
import AddUserModal from './AddUserModal';

export default function Users(props: any) {
  const {
    columns,
    QuickSearchToolbar,
    rows,
    searchText,
    requestSearch,
    toastOpen,
    setOpenToast,
    isLoading,
    roleModalOpen,
    closeRoleModal,
    renderConfirmDialog,
    toolbarButtons,
    adminModalOpen,
    setAdminModalOpen,
    handleAdminRightsConfirm,
    selectedUser,
    addUserModalOpen,
    setAddUserModalOpen,
    handleCreateUser,
  } = UsersLogic({ 
    rows: props.rows, 
    setRows: props.setRows,
    userType: props.userType
  });

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <Box sx={{ height: props.height, width: 1 }}>
        {toolbarButtons}
        <DataGrid
          disableSelectionOnClick
          components={{ Toolbar: QuickSearchToolbar }}
          rows={rows}
          columns={columns}
          loading={isLoading}
          componentsProps={{
            toolbar: {
              value: searchText,
              onChange: (event) => requestSearch(event?.target.value),
              clearSearch: () => requestSearch(""),
            },
          }}
        />

        <Snackbar
          open={toastOpen}
          autoHideDuration={6000}
          onClose={() => setOpenToast(false)}
          message="Operation successful"
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        />

        {renderConfirmDialog()}
      </Box>
      
      {roleModalOpen && (
        <RoleModal
          onClose={closeRoleModal}
          user={roleModalOpen}
          onSelect={(role) => {
            console.log("role selected", role);
          }}
        />
      )}

      {selectedUser && (
        <AdminRightsModal
          open={adminModalOpen}
          onClose={() => setAdminModalOpen(false)}
          onConfirm={handleAdminRightsConfirm}
          userEmail={selectedUser.col1}
          isAdmin={selectedUser.isAdmin}
          currentPermissions={selectedUser.permissions}
        />
      )}

      <AddUserModal
        open={addUserModalOpen}
        onClose={() => setAddUserModalOpen(false)}
        onConfirm={handleCreateUser}
      />
    </Paper>
  );
}
