import { ReactNode, useMemo } from "react";
import { FC, createContext } from "react";
import { PureAbility, createMongoAbility } from "@casl/ability";
import useSWR from "swr";
import { API } from "aws-amplify";
import { Actions, AppAbility, RulesType, Subjects } from "./PolicyTypes";
interface CanInput {
  subject: Subjects;
  action: Actions;
}

interface AuthorizerContextType {
  can: (input: CanInput) => boolean;
  isOrgAdmin: () => boolean;
  hasAdminPermission: (permission: string) => boolean;
  userId: string;
  isAdministrator: boolean;
  organizationId: string;
  isLoading: boolean;
  inOrgPermissions: {
    hasHPUpdatePermissions: boolean;
    canViewCustomLogs: boolean;
    canCreateHP: boolean;
    canConnectGateway: boolean;
  };
  mqttConnectionOptions?: {
    host: string;
    signature: string;
    authorizerName: string;
    tokenValue: string;
    authToken: string;
  };
}

export type RoleGrant = {
  action: string;
  subject: string;
  conditions?: any;
};

export const AuthorizerContext = createContext<AuthorizerContextType | null>(
  null
);

interface AuthorizerProviderProps {
  children: ReactNode;
}

const getUserData = () => {
  const apiName = "ThermonovaAPI";
  const path = "/users/me";
  return API.get(apiName, path, {});
};

export const AuthorizerProvider: FC<AuthorizerProviderProps> = (props) => {
  const { children } = props;

  const { data, error, isLoading } = useSWR("getUserData", getUserData);
  const ability = useMemo(() => {
    if (isLoading || error || !data?.policies) {
      return new PureAbility([]);
    }
    const createAbility = (rules: RulesType) =>
      createMongoAbility<AppAbility>(rules, {
        detectSubjectType: (object) => object.__typename,
      });
    console.log({
      policies: data.policies,
    });
    const ability = createAbility(data.policies);
    return ability;
  }, [data, error, isLoading]);

  const can = ({ action, subject }: CanInput): boolean => {
    return ability.can(action, subject);
  };

  const isOrgAdmin = () => {
    return data?.roleInOrganization === "admin";
  };

  const inOrgPermissions = useMemo(() => {
    const denyAll = {
      hasHPUpdatePermissions: false,
      canViewCustomLogs: false,
      canCreateHP: false,
      canConnectGateway: false,
    };
    const allowAll = {
      hasHPUpdatePermissions: true,
      canViewCustomLogs: true,
      canCreateHP: true,
      canConnectGateway: true,
    };
    if (!data) {
      return denyAll;
    }
    if (data?.isAdministrator) {
      return allowAll;
    }
    if (!data?.organizationId) {
      return denyAll;
    }
    const currentOrganization = data["userOrganization"];
    const orgPermissions = currentOrganization?.permissions || {};
    const hasHPUpdatePermissions =
      data.isAdministrator || orgPermissions?.heatpump_update;
    const canCreateHP = data.isAdministrator || orgPermissions?.heatpump_create;
    const canConnectGateway = data.isAdministrator;
    return {
      hasHPUpdatePermissions,
      canViewCustomLogs: data?.isAdministrator,
      canCreateHP,
      canConnectGateway,
    };
  }, [data]);

  const hasAdminPermission = (permission: string): boolean => {
    if (!data?.permissions) {
      return false;
    }
    return data.permissions.includes(permission);
  };

  return (
    <AuthorizerContext.Provider
      value={{
        can,
        isOrgAdmin,
        hasAdminPermission,
        userId: data?.userId,
        isAdministrator: data?.isAdministrator,
        organizationId: data?.organizationId,
        isLoading,
        inOrgPermissions,
        mqttConnectionOptions: {
          host: data?.mqttConnectionOptions?.host,
          signature: data?.mqttConnectionOptions?.signature,
          authorizerName: data?.mqttConnectionOptions?.authorizerName,
          tokenValue: data?.mqttConnectionOptions?.tokenValue,
          authToken: data?.mqttConnectionOptions?.authToken,
        },
      }}
    >
      {children}
    </AuthorizerContext.Provider>
  );
};
