import React from "react";
import { API } from "aws-amplify";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import { GridColDef } from "@mui/x-data-grid";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";
import { useSnackbar } from "notistack";
import CircularProgress from "@mui/material/CircularProgress";

type Output = {
  id: string;
  col1: string;
  col2: string;
  col3: string;
  isAdmin: "Yes" | "No";
  userEnabled: boolean;
  emailVerified: boolean;
  gotIdentity: boolean;
  Role: string;
  permissions?: string[];
};

type UsersLogicProps = {
  rows: Output[];
  setRows: React.Dispatch<React.SetStateAction<Output[]>>;
  roleModalOpen?: boolean;
  userType?: "end-users" | "thermonova-users";
};
export default function UsersLogic(props: UsersLogicProps) {
  const { rows, setRows } = props;
  const [isLoading, setIsLoading] = React.useState(true);

  const [fullRows, setFullRows] = React.useState<Output[]>([]);
  const [searchText, setSearchText] = React.useState("");
  const [toastOpen, setOpenToast] = React.useState(false);
  const [roleModalOpen, setRoleModalOpen] = React.useState(false);
  const [confirmDialog, setConfirmDialog] = React.useState<{
    open: boolean;
    title: string;
    message: string;
    action: () => void;
  }>({
    open: false,
    title: "",
    message: "",
    action: () => {},
  });
  const [adminModalOpen, setAdminModalOpen] = React.useState(false);
  const [selectedUser, setSelectedUser] = React.useState<Output | null>(null);
  const [addUserModalOpen, setAddUserModalOpen] = React.useState(false);
  const [isCacheRevalidating, setIsCacheRevalidating] = React.useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const closeRoleModal = () => {
    setRoleModalOpen(false);
  };

  const columns: GridColDef[] = [
    { field: "col1", headerName: "Email", width: 350 },
    { field: "col2", headerName: "Created", width: 140 },
    { field: "col3", headerName: "Status", width: 100 },
    {
      field: "col4",
      headerName: "Actions",
      width: 550,
      align: "right",
      renderCell: (cellValues) => {
        // Initialize buttons array
        const buttons = [];
        
        // Add admin and enable/disable buttons only if user has identity
        if (cellValues.row.gotIdentity) {
          if (props.userType === "thermonova-users") {
            buttons.push(
              <Button
                key="admin"
                size="small"
                variant="contained"
                value={cellValues.row.id}
                onClick={(event) => {
                  handleClickAdmin(event);
                }}
              >
                {cellValues.row.isAdmin === "No" ? "Add Permissions" : "Manage Permissions"}
              </Button>
            );
          }

          buttons.push(
            <Button
              key="enable"
              size="small"
              variant="outlined"
              value={cellValues.row.id}
              onClick={() => {
                disableAccount(cellValues.row, !cellValues.row.userEnabled);
              }}
            >
              {cellValues.row.userEnabled ? "Disable Account" : "Enable Account"}
            </Button>
          );
        }

        // Add delete button for all end users regardless of identity status
        if (props.userType === "end-users" || props.userType === "thermonova-users") {
          buttons.push(
            <Button
              key="delete"
              size="small"
              variant="outlined"
              color="error"
              value={cellValues.row.id}
              onClick={() => handleDeleteUser(cellValues.row)}
            >
              Delete User
            </Button>
          );
        }

        // Return the stack of buttons if there are any buttons to show
        return buttons.length > 0 ? (
          <Stack spacing={2} direction="row" justifyContent="flex-end">
            {buttons}
          </Stack>
        ) : "";
      },
    },
  ];

  const handleConfirmClose = () => {
    setConfirmDialog({ ...confirmDialog, open: false });
  };

  const disableAccount = (row: Output, disable: boolean) => {
    setConfirmDialog({
      open: true,
      title: disable ? "Disable Account" : "Enable Account",
      message: disable 
        ? `Are you sure you want to disable the account for ${row.col1}?`
        : `Are you sure you want to enable the account for ${row.col1}?`,
      action: () => {
        const apiName = "ThermonovaAPI";
        const path = "/users/user";
        const myInit = {
          body: {
            username: row?.id,
            disable,
          },
        };

        API.post(apiName, path, myInit)
          .then((response) => {
            const index = rows.findIndex((item) => item.id === row?.id);
            const output = [...rows];
            output[index].userEnabled = !disable;
            setRows(output);
            setFullRows(output);
            setOpenToast(true);
          })
          .catch((error) => {
            console.log(error.response);
          });
      },
    });
  };

  const handleClickAdmin = (e: any) => {
    const userId = e.target?.value;
    const user = rows.find(row => row.id === userId);
    if (!user) return;
    
    setSelectedUser(user);
    setAdminModalOpen(true);
  };

  const handleDeleteUser = (row: Output) => {
    setConfirmDialog({
      open: true,
      title: "Delete User",
      message: `Are you sure you want to delete the user ${row.col1}? This action cannot be undone.`,
      action: () => {
        const apiName = "ThermonovaAPI";
        const path = "/users/delete";
        const myInit = {
          body: {
            username: row?.id
          },
        };

        API.post(apiName, path, myInit)
          .then((response) => {
            const filteredRows = rows.filter((item) => item.id !== row?.id);
            setRows(filteredRows);
            setFullRows(filteredRows);
            enqueueSnackbar(`User ${row.col1} has been successfully deleted`, {
              variant: 'success',
            });
            handleConfirmClose();
          })
          .catch((error) => {
            console.log(error.response);
            enqueueSnackbar(`Failed to delete user: ${error.message}`, {
              variant: 'error',
            });
            handleConfirmClose();
          });
      },
    });
  };

  const handleCacheRevalidation = () => {
    setConfirmDialog({
      open: true,
      title: "Revalidate Cache",
      message: "Are you sure you want to revalidate the users cache? This action will refresh all user data.",
      action: async () => {
        try {
          setIsCacheRevalidating(true);
          const apiName = "ThermonovaAPI";
          const path = "/users/cache/admin/revalidate";
          const myInit = {
            body: {}
          };

          await API.post(apiName, path, myInit);
          
          // Refresh the users list after cache revalidation
          const output: Output[] = [];
          const usersResponse = await getUsers();
          usersResponse?.forEach((element: any) => {
            output.push(createData(element));
          });
          setRows(output);
          setFullRows(output);
          
          enqueueSnackbar("Cache revalidation successful", {
            variant: 'success',
          });
          handleConfirmClose(); // Only close after success
        } catch (error) {
          const errorMessage = error.response?.data?.message 
            || error.response?.message 
            || error.message 
            || 'Cache revalidation failed';
          
          enqueueSnackbar(errorMessage, {
            variant: 'error',
          });
        } finally {
          setIsCacheRevalidating(false);
        }
      },
    });
  };

  const handleAdminRightsConfirm = async (permissions: string[] | null) => {
    if (!selectedUser) return;
    
    const apiName = "ThermonovaAPI";
    const path = "/users/user";
    const myInit = {
      body: { 
        user: selectedUser.id, 
        action: permissions === null ? "revoke_admin" : "grant_admin",
        permissions 
      },
    };

    try {
      const response = await API.put(apiName, path, myInit);
      
      if (response.Access === "Granted") {
        const output: Output[] = [];
        const usersResponse = await getUsers();
        usersResponse?.forEach((element: any) => {
          output.push(createData(element));
        });
        setRows(output);
        setFullRows(output);
        
        enqueueSnackbar(
          permissions === null 
            ? "Admin access successfully revoked" 
            : "Permissions successfully updated", 
          { variant: 'success' }
        );
      }
      
      setAdminModalOpen(false);
      setSelectedUser(null);
    } catch (error) {
      throw new Error(error.response?.message || 'Failed to update permissions');
    }
  };

  type QuickSearchToolbarProps = {
    clearSearch: () => void;
    onChange: React.ChangeEventHandler<HTMLInputElement>;
    value: string;
  };

  function QuickSearchToolbar(props: QuickSearchToolbarProps) {
    return (
      <Box
        sx={{
          p: 0.5,
          pb: 0,
        }}
      >
        <TextField
          autoFocus
          variant="standard"
          value={props.value}
          onChange={props.onChange}
          placeholder="Search…"
          InputProps={{
            startAdornment: <SearchIcon fontSize="small" />,
            endAdornment: (
              <IconButton
                title="Clear"
                aria-label="Clear"
                size="small"
                style={{ visibility: props.value ? "visible" : "hidden" }}
                onClick={props.clearSearch}
              >
                <ClearIcon fontSize="small" />
              </IconButton>
            ),
          }}
          sx={{
            width: {
              xs: 1,
              sm: "auto",
            },
            m: (theme) => theme.spacing(1, 0.5, 1.5),
            "& .MuiSvgIcon-root": {
              mr: 0.5,
            },
            "& .MuiInput-underline:before": {
              borderBottom: 1,
              borderColor: "divider",
            },
          }}
        />
      </Box>
    );
  }
  QuickSearchToolbar.propTypes = {
    clearSearch: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
  };

  function escapeRegExp(value: string) {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
  }

  const requestSearch = (searchValue: string) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
    const filteredRows = fullRows.filter((row: any) => {
      return Object.keys(row).some((field: string) => {
        return row[field] != null && searchRegex.test(row[field].toString());
      });
    });
    setRows(filteredRows);
  };

  function createData(data: any): Output {
    let ident;
    if ("custom:IdentityID" in data) {
      ident = true;
    } else {
      ident = false;
    }
    return {
      id: data["username"],
      col1: data["email"],
      col2: data["UserCreated"],
      col3: data["UserState"],
      isAdmin: data["isAdmin"],
      userEnabled: data["userEnabled"],
      emailVerified: data["email_verified"],
      gotIdentity: ident,
      Role: data["Role"] || "",
      permissions: data["permissions"],
      ...data,
    };
  }

  function getUsers() {
    const apiName = "ThermonovaAPI";
    const path = "/users";
    const myInit = {
      // OPTIONAL
      headers: {}, // OPTIONAL
    };

    return API.get(apiName, path, myInit);
  }

  // function pushUserData(data: { 
  //   user: any; 
  //   action: string;
  //   permissions?: string[];
  // }) {
  //   const apiName = "ThermonovaAPI";
  //   const path = "/users/user";
  //   const myInit = {
  //     // OPTIONAL
  //     body: data,
  //   };

  //   API.put(apiName, path, myInit)
  //     .then((response) => {
  //       if (response.Access === "Granted") {
  //         const output: Output[] = [];
  //         getUsers().then((response) => {
  //           response?.forEach((element: any) => {
  //             output.push(createData(element));
  //           });
  //           setRows(output);
  //           setFullRows(output);
  //         });
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error.response);
  //     });
  // }

  const handleCreateUser = async (data: { 
    email: string; 
    role: "User" | "Administrator";
    permissions?: string[];
  }) => {
    const apiName = "ThermonovaAPI";
    const path = "/users/create";
    const myInit = {
      body: data
    };

    try {
      await API.post(apiName, path, myInit);
      
      // Refresh user list
      const output: Output[] = [];
      const usersResponse = await getUsers();
      usersResponse?.forEach((element: any) => {
        output.push(createData(element));
      });
      setRows(output);
      setFullRows(output);
      
      enqueueSnackbar("User created successfully", { variant: 'success' });
    } catch (error) {
      // AWS Amplify API error handling
      const errorMessage = error.response?.data?.message  // API Gateway/Lambda error messages
        || error.response?.message  // Direct service error messages
        || error.message  // Generic error messages
        || 'Failed to create user';
      throw new Error(errorMessage);
    }
  };

  React.useEffect(() => {
    const output: Output[] = [];
    setIsLoading(true);
    getUsers().then((response: any) => {
      if (!Array.isArray(response)) {
        setIsLoading(false);
        setRows([]);
        return;
      }
      response?.forEach((element: any) => {
        output.push(createData(element));
      });
      setIsLoading(false);
      setRows(output);
      setFullRows(output);
      //console.log(output)
    });
  }, [setRows, setFullRows]);

  const renderConfirmDialog = () => {
    return (
      <Dialog
        open={confirmDialog.open}
        onClose={!isCacheRevalidating ? handleConfirmClose : undefined}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {confirmDialog.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {confirmDialog.message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={handleConfirmClose}
            disabled={isCacheRevalidating}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              confirmDialog.action();
            }}
            autoFocus
            variant="contained"
            color="primary"
            disabled={isCacheRevalidating}
            startIcon={isCacheRevalidating ? <CircularProgress size={20} /> : null}
          >
            {isCacheRevalidating ? 'Revalidating...' : 'Confirm'}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const toolbarButtons = props.userType === "thermonova-users" ? (
    <Box sx={{ mb: 2, display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
      <Button
        variant="contained"
        color="primary"
        onClick={() => setAddUserModalOpen(true)}
        size="small"
        disabled={isCacheRevalidating}
      >
        Add User
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={handleCacheRevalidation}
        size="small"
        disabled={isCacheRevalidating}
        startIcon={isCacheRevalidating ? <CircularProgress size={20} /> : null}
      >
        {isCacheRevalidating ? 'Revalidating...' : 'Revalidate Cache'}
      </Button>
    </Box>
  ) : null;

  return {
    columns,
    QuickSearchToolbar,
    rows,
    searchText,
    requestSearch,
    toastOpen,
    setOpenToast,
    isLoading,
    roleModalOpen,
    closeRoleModal,
    renderConfirmDialog,
    toolbarButtons,
    adminModalOpen,
    setAdminModalOpen,
    handleAdminRightsConfirm,
    selectedUser,
    addUserModalOpen,
    setAddUserModalOpen,
    handleCreateUser,
  };
}
