import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Typography,
  Box,
  Divider,
  Alert,
  CircularProgress,
} from '@mui/material';
import { ADMIN_PERMISSIONS } from './AdminRightsModal';

interface AddUserModalProps {
  open: boolean;
  onClose: () => void;
  onConfirm: (data: { 
    email: string; 
    role: "User" | "Administrator";
    permissions?: string[];
  }) => Promise<void>;
}

const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

export default function AddUserModal({ open, onClose, onConfirm }: AddUserModalProps) {
  const [email, setEmail] = React.useState('');
  const [isAdmin, setIsAdmin] = React.useState(false);
  const [fullAdmin, setFullAdmin] = React.useState(false);
  const [selectedPermissions, setSelectedPermissions] = React.useState<string[]>([]);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [error, setError] = React.useState<string | null>(null);

  // Email validation
  const isEmailValid = EMAIL_REGEX.test(email);
  const showEmailError = email.length > 0 && !isEmailValid;

  const handlePermissionChange = React.useCallback((permission: string) => {
    setSelectedPermissions(prev => {
      if (prev.includes(permission)) {
        return prev.filter(p => p !== permission);
      }
      return [...prev, permission];
    });
  }, []);

  const handleFullAdminChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setFullAdmin(event.target.checked);
    if (event.target.checked) {
      setSelectedPermissions(ADMIN_PERMISSIONS.map(p => p.value));
    } else {
      setSelectedPermissions([]);
    }
  }, []);

  const handleSubmit = async () => {
    try {
      setError(null);
      setIsSubmitting(true);

      await onConfirm({
        email,
        role: isAdmin ? "Administrator" : "User",
        ...(isAdmin && !fullAdmin ? { permissions: selectedPermissions } : {})
      });

      // Reset form and close on success
      handleReset();
      onClose();
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to create user');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleReset = () => {
    setEmail('');
    setIsAdmin(false);
    setFullAdmin(false);
    setSelectedPermissions([]);
    setError(null);
  };

  React.useEffect(() => {
    if (open) {
      handleReset();
    }
  }, [open]);

  const isValid = isEmailValid && (!isAdmin || fullAdmin || selectedPermissions.length > 0);

  return (
    <Dialog 
      open={open} 
      onClose={!isSubmitting ? onClose : undefined}
      maxWidth="sm" 
      fullWidth
    >
      <DialogTitle>
        Add New User
      </DialogTitle>
      <DialogContent>
        {error && (
          <Alert 
            severity="error" 
            sx={{ mb: 2 }}
            onClose={() => setError(null)}
          >
            {error}
          </Alert>
        )}

        <Box sx={{ mt: 1 }}>
          <TextField
            label="Email"
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            error={showEmailError}
            helperText={showEmailError ? "Please enter a valid email address" : ""}
            disabled={isSubmitting}
            sx={{ mb: 2 }}
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={isAdmin}
                onChange={(e) => {
                  setIsAdmin(e.target.checked);
                  if (!e.target.checked) {
                    setFullAdmin(false);
                    setSelectedPermissions([]);
                  }
                }}
                disabled={isSubmitting}
              />
            }
            label="Create as Administrator"
          />
          <Typography variant="body2" color="text.secondary" sx={{ ml: 4, mb: 2 }}>
            By default, new users have no administrative rights and cannot access the dashboard. 
            You can grant specific permissions now or modify them later.
          </Typography>

          {isAdmin && (
            <>
              <Divider sx={{ my: 2 }} />
              
              <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                Select the level of administrative access:
              </Typography>

              <Box 
                sx={{ 
                  mb: 3,
                  p: 2,
                  bgcolor: 'action.hover',
                  borderRadius: 1,
                  border: '1px solid',
                  borderColor: 'divider'
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={fullAdmin}
                      onChange={handleFullAdminChange}
                      disabled={isSubmitting}
                    />
                  }
                  label="Grant full administrator rights"
                />
                <Typography variant="body2" color="text.secondary" sx={{ ml: 4 }}>
                  User will have access to all administrative features and permissions
                </Typography>
              </Box>
              
              {!fullAdmin && (
                <>                  
                  <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                    Or select specific permissions for this administrator:
                  </Typography>

                  <FormGroup>
                    {ADMIN_PERMISSIONS.map((permission) => (
                      <Box key={permission.value} sx={{ mb: 2 }}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={selectedPermissions.includes(permission.value)}
                              onChange={() => handlePermissionChange(permission.value)}
                              disabled={isSubmitting}
                            />
                          }
                          label={permission.label}
                        />
                        <Typography variant="body2" color="text.secondary" sx={{ ml: 4 }}>
                          {permission.description}
                        </Typography>
                      </Box>
                    ))}
                  </FormGroup>
                </>
              )}
            </>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button 
          onClick={onClose}
          disabled={isSubmitting}
        >
          Cancel
        </Button>
        <Button 
          onClick={handleSubmit}
          variant="contained"
          disabled={isSubmitting || !isValid}
          startIcon={isSubmitting ? <CircularProgress size={20} /> : null}
        >
          {isSubmitting ? 'Creating...' : 'Create User'}
        </Button>
      </DialogActions>
    </Dialog>
  );
} 