import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Typography,
  Box,
  Divider,
  Alert,
  CircularProgress,
} from '@mui/material';

export const ADMIN_PERMISSIONS = [
  {
    value: 'heatpump_administrator',
    label: 'Heat Pump Administrator',
    description: 'Can manage heat pump configurations and settings'
  },
  {
    value: 'gateway_administrator',
    label: 'Gateway Administrator',
    description: 'Can manage all gateway-related settings and configurations'
  },
  {
    value: 'software_administrator',
    label: 'Software Administrator',
    description: 'Can manage software updates and configurations'
  },
  {
    value: 'roles_administrator',
    label: 'Roles Administrator',
    description: 'Can manage user roles and permissions'
  },
  {
    value: 'access_administrator',
    label: 'Access Administrator',
    description: 'Can manage user access and authentication settings'
  }
];

interface AdminRightsModalProps {
  open: boolean;
  onClose: () => void;
  onConfirm: (permissions: string[] | null) => Promise<void>;
  userEmail: string;
  isAdmin?: "Yes" | "No";
  currentPermissions?: string[];
}

export default function AdminRightsModal({ 
  open, 
  onClose, 
  onConfirm, 
  userEmail, 
  isAdmin,
  currentPermissions: rawPermissions
}: AdminRightsModalProps) {
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [error, setError] = React.useState<string | null>(null);

  // Modify the currentPermissions logic to handle full admin case
  const currentPermissions = React.useMemo(() => {
    // If user is admin and has no permissions specified, they have all permissions
    if (isAdmin === "Yes" && (!Array.isArray(rawPermissions) || rawPermissions.length === 0)) {
      return ADMIN_PERMISSIONS.map(p => p.value);
    }
    // Otherwise, use the provided permissions or empty array
    return Array.isArray(rawPermissions) ? rawPermissions : [];
  }, [rawPermissions, isAdmin]);

  const [selectedPermissions, setSelectedPermissions] = React.useState<string[]>([]);
  const [fullAdmin, setFullAdmin] = React.useState(false);
  const [revokeAdmin, setRevokeAdmin] = React.useState(false);

  // Initialize state when modal opens
  React.useEffect(() => {
    if (!open) return;

    setSelectedPermissions(currentPermissions);
    // Set fullAdmin to true if user has all permissions
    setFullAdmin(ADMIN_PERMISSIONS.every(p => currentPermissions.includes(p.value)));
    setRevokeAdmin(false);
  }, [open, currentPermissions]);

  const handlePermissionChange = React.useCallback((permission: string) => {
    setSelectedPermissions(prev => {
      if (prev.includes(permission)) {
        return prev.filter(p => p !== permission);
      }
      return [...prev, permission];
    });
  }, []);

  const handleFullAdminChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setFullAdmin(event.target.checked);
    if (event.target.checked) {
      setSelectedPermissions(ADMIN_PERMISSIONS.map(p => p.value));
    } else {
      setSelectedPermissions([]);
    }
  }, []);

  const handleConfirm = async () => {
    try {
      setError(null);
      setIsSubmitting(true);
      
      await onConfirm(revokeAdmin ? null : selectedPermissions);
      
      // Only close if successful
      onClose();
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to update permissions');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Dialog 
      open={open} 
      onClose={!isSubmitting ? onClose : undefined}  // Prevent closing while submitting
      maxWidth="sm" 
      fullWidth
    >
      <DialogTitle>
        Manage Permissions
      </DialogTitle>
      <DialogContent>
        {error && (
          <Alert 
            severity="error" 
            sx={{ mb: 2 }}
            onClose={() => setError(null)}
          >
            {error}
          </Alert>
        )}

        <Typography variant="body1" gutterBottom>
          Configure admin permissions for <strong>{userEmail}</strong>
        </Typography>
        
        {!revokeAdmin && (
          <>
            <Box sx={{ my: 2 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={fullAdmin}
                    onChange={handleFullAdminChange}
                  />
                }
                label="Grant full administrator rights"
              />
            </Box>
            
            <Divider sx={{ my: 2 }} />
            
            <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
              {fullAdmin 
                ? "User will have all administrative permissions" 
                : "Select specific permissions for this administrator"}
            </Typography>

            <FormGroup>
              {ADMIN_PERMISSIONS.map((permission) => (
                <Box key={permission.value} sx={{ mb: 2 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedPermissions.includes(permission.value)}
                        onChange={() => handlePermissionChange(permission.value)}
                        disabled={fullAdmin}
                      />
                    }
                    label={permission.label}
                  />
                  <Typography variant="body2" color="text.secondary" sx={{ ml: 4 }}>
                    {permission.description}
                  </Typography>
                </Box>
              ))}
            </FormGroup>
          </>
        )}

        {isAdmin === "Yes" && (
          <Box sx={{ mt: 3 }}>
            <Alert 
              severity="error" 
              sx={{ 
                mb: 2,
                '& .MuiAlert-message': {
                  width: '100%'
                }
              }}
            >
              <Typography variant="subtitle2" gutterBottom>
                Danger Zone
              </Typography>
              <Typography variant="body2" gutterBottom>
                Revoking admin access will remove all permissions from this user.
              </Typography>
              <Box sx={{ mt: 1 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={revokeAdmin}
                      onChange={(e) => {
                        setRevokeAdmin(e.target.checked);
                        if (e.target.checked) {
                          setFullAdmin(false);
                        }
                      }}
                    />
                  }
                  label="I understand, revoke all admin access"
                />
              </Box>
            </Alert>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button 
          onClick={onClose}
          disabled={isSubmitting}
        >
          Cancel
        </Button>
        <Button 
          onClick={handleConfirm}
          variant="contained"
          color={revokeAdmin ? "error" : "primary"}
          disabled={isSubmitting || (!revokeAdmin && selectedPermissions.length === 0)}
          startIcon={isSubmitting ? <CircularProgress size={20} /> : null}
        >
          {isSubmitting 
            ? 'Saving...' 
            : (revokeAdmin ? "Revoke Admin Access" : "Save Permissions")
          }
        </Button>
      </DialogActions>
    </Dialog>
  );
} 