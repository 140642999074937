import React from "react";
import { useForm } from "react-hook-form";
import { useSearchParams, useNavigate, Link } from "react-router-dom";
import { Auth } from "aws-amplify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  Box,
  Button,
  TextField,
  Typography,
  Alert,
  CircularProgress,
  Paper,
  Link as MuiLink,
} from "@mui/material";
import Logo from "../../../img/svg/logo.svg";
import { Resolver } from "react-hook-form";

type FormInputs = {
  code: string;
  password: string;
  confirmPassword: string;
};

const schema: yup.ObjectSchema<FormInputs> = yup.object({
  code: yup.string().required("Verification code is required"),
  password: yup
    .string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]/,
      "Password must contain at least one uppercase letter, one lowercase letter, one number and one special character"
    ),
  confirmPassword: yup
    .string()
    .required("Please confirm your password")
    .oneOf([yup.ref("password")], "Passwords must match"),
}).required();

export default function ResetPassword() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [vieporth, setVieporth] = React.useState<number>();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<string | null>(null);
  const [success, setSuccess] = React.useState(false);

  const username = searchParams.get("username");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormInputs>({
    resolver: yupResolver<FormInputs>(schema) as Resolver<FormInputs>,
    defaultValues: {
      code: '',
      password: '',
      confirmPassword: ''
    }
  });

  React.useEffect(() => {
    setVieporth(window.innerHeight / 2 - 350);
    
    if (!username) {
      navigate("/");
    }
  }, [username, navigate]);

  const onSubmit = async (data: FormInputs) => {
    setLoading(true);
    setError(null);
    
    try {
      await Auth.forgotPasswordSubmit(
        username!,
        data.code,
        data.password
      );
      setSuccess(true);
      setTimeout(() => {
        navigate("/");
      }, 3000);
    } catch (err: any) {
      setError(
        err.message || "An error occurred while resetting your password"
      );
    } finally {
      setLoading(false);
    }
  };

  if (!username) {
    return null;
  }

  return (
    <Box sx={{ bgcolor: '#F7FAFC', minHeight: '100vh' }}>
      <div>
        <img
          src={Logo}
          style={{
            width: "16rem",
            position: "absolute",
            top: vieporth,
            left: "calc(50% - 8rem)",
            display: "block",
          }}
          alt="Thermonova A/S"
        />
      </div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
          px: 2,
        }}
      >
        <Paper
          elevation={0}
          sx={{
            p: { xs: 3, sm: 4 },
            width: "100%",
            maxWidth: 400,
            mt: 8,
            borderRadius: 3,
            boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
          }}
        >
          <Typography 
            variant="h5" 
            component="h1" 
            gutterBottom 
            sx={{ 
              mb: 0.5,
              color: '#2D3748',
              fontWeight: 600,
              fontSize: '1.5rem',
            }}
          >
            Reset your password
          </Typography>
          <Typography
            variant="body2"
            sx={{
              mb: 4,
              color: '#718096',
            }}
          >
            Enter your verification code and create a new password
          </Typography>
          
          {success ? (
            <Alert 
              severity="success"
              sx={{
                borderRadius: 2,
                '& .MuiAlert-message': {
                  fontSize: '0.875rem',
                }
              }}
            >
              Password reset successful! Redirecting to login...
            </Alert>
          ) : (
            <form onSubmit={handleSubmit(onSubmit)}>
              <Typography 
                variant="subtitle2" 
                sx={{ 
                  mb: 1,
                  color: '#4A5568',
                  fontWeight: 500,
                  fontSize: '0.875rem',
                }}
              >
                Verification Code
              </Typography>
              <TextField
                {...register("code")}
                fullWidth
                placeholder="Enter the code from your email"
                error={!!errors.code}
                helperText={errors.code?.message}
                disabled={loading}
                autoComplete="off"
                size="small"
                sx={{
                  mb: 3,
                  '& .MuiOutlinedInput-root': {
                    borderRadius: 1.5,
                    '& fieldset': {
                      borderColor: '#E2E8F0',
                    },
                    '&:hover fieldset': {
                      borderColor: '#CBD5E0',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#EF6416',
                    },
                  },
                  '& input::placeholder': {
                    color: '#A0AEC0',
                    fontSize: '0.875rem',
                  },
                  '& .MuiFormHelperText-root': {
                    fontSize: '0.75rem',
                  }
                }}
              />
              
              <Typography 
                variant="subtitle2" 
                sx={{ 
                  mb: 1,
                  color: '#4A5568',
                  fontWeight: 500,
                  fontSize: '0.875rem',
                }}
              >
                New Password
              </Typography>
              <TextField
                {...register("password")}
                type="password"
                fullWidth
                placeholder="Enter your new password"
                error={!!errors.password}
                helperText={errors.password?.message}
                disabled={loading}
                autoComplete="new-password"
                size="small"
                sx={{
                  mb: 3,
                  '& .MuiOutlinedInput-root': {
                    borderRadius: 1.5,
                    '& fieldset': {
                      borderColor: '#E2E8F0',
                    },
                    '&:hover fieldset': {
                      borderColor: '#CBD5E0',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#EF6416',
                    },
                  },
                  '& input::placeholder': {
                    color: '#A0AEC0',
                    fontSize: '0.875rem',
                  },
                  '& .MuiFormHelperText-root': {
                    fontSize: '0.75rem',
                  }
                }}
              />

              <Typography 
                variant="subtitle2" 
                sx={{ 
                  mb: 1,
                  color: '#4A5568',
                  fontWeight: 500,
                  fontSize: '0.875rem',
                }}
              >
                Confirm Password
              </Typography>
              <TextField
                {...register("confirmPassword")}
                type="password"
                fullWidth
                placeholder="Confirm your new password"
                error={!!errors.confirmPassword}
                helperText={errors.confirmPassword?.message}
                disabled={loading}
                autoComplete="new-password"
                size="small"
                sx={{
                  mb: 3,
                  '& .MuiOutlinedInput-root': {
                    borderRadius: 1.5,
                    '& fieldset': {
                      borderColor: '#E2E8F0',
                    },
                    '&:hover fieldset': {
                      borderColor: '#CBD5E0',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#EF6416',
                    },
                  },
                  '& input::placeholder': {
                    color: '#A0AEC0',
                    fontSize: '0.875rem',
                  },
                  '& .MuiFormHelperText-root': {
                    fontSize: '0.75rem',
                  }
                }}
              />

              {error && (
                <Alert 
                  severity="error" 
                  sx={{ 
                    mt: 2, 
                    mb: 2,
                    borderRadius: 2,
                    '& .MuiAlert-message': {
                      fontSize: '0.875rem',
                    }
                  }}
                >
                  {error}
                </Alert>
              )}

              <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3, alignItems: 'center' }}>
                <MuiLink
                  component={Link}
                  to="/"
                  sx={{
                    color: '#718096',
                    textDecoration: 'none',
                    fontSize: '0.875rem',
                    fontWeight: 500,
                    '&:hover': {
                      color: '#4A5568',
                    }
                  }}
                >
                  ← Back to Sign In
                </MuiLink>

                <Button
                  type="submit"
                  variant="contained"
                  disabled={loading}
                  sx={{
                    bgcolor: '#EF6416',
                    color: 'white',
                    px: 4,
                    py: 1.5,
                    borderRadius: 1.5,
                    textTransform: 'none',
                    fontWeight: 600,
                    fontSize: '0.875rem',
                    boxShadow: 'none',
                    '&:hover': {
                      bgcolor: '#d55a14',
                      boxShadow: 'none',
                    },
                  }}
                >
                  {loading ? (
                    <CircularProgress size={20} color="inherit" />
                  ) : (
                    "Reset Password"
                  )}
                </Button>
              </Box>
            </form>
          )}
        </Paper>
      </Box>
    </Box>
  );
}
